{
    "SiteNameOverride": null,
    "WelcomeModalTitleOverride": null,
    "WelcomeModalBodyOverride": null,
    "WelcomeModalLocalStorageKey": "no-welcome-modal",
    "ChatSound": "//computernewb.com/collab-vm/notify.ogg",
    "ServerAddresses": [
        "ws://127.0.0.1:6004"
    ],
    "ServerAddressesListURI": null,
    "NSFWVMs": ["vm0b0t"],
    "RawMessages": {
        "VMTitles": true,
        "Messages": true
    },
    "Auth": {
        "Enabled": false,
        "APIEndpoint": "http://127.0.0.1:5858"
    }
}
